import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';
import { Subscription } from 'rxjs';

/**
 * Input Validator used for honeypotting form field
 * - visually behaves like a required field
 * - needs to stay empty or the form is invalid
 */
@Directive({
  selector: '[appInputForbiddenValidator]',
  inputs: ['appInputForbiddenValidator.useTranslationKey', 'appInputForbiddenValidator.trigger'],
  exportAs: 'appInputForbiddenValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputForbiddenValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputForbiddenValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appInputForbiddenValidator';
  protected override translationKey = 'validation.input.appInputForbiddenValidator';

  protected ariaDescriptionTranslationSub: Subscription;

  override ngOnInit() {
    super.ngOnInit();

    if (this.host) {
      this.host.hasRequiredValidator = true;
      (this.host as InputComponent).cancelBrowserAutocomplete = true;
      this.ariaDescriptionTranslationSub = this.translationService.translate('validation.input.appInputForbiddenValidator').subscribe(tr => this.host.ariaDescription = tr);
    }

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.host) {
      this.host.hasRequiredValidator = false;
    }
    this.ariaDescriptionTranslationSub?.unsubscribe();
  }

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appInputForbiddenValidator': this.translation};
    return control?.value ? errors : null;
  };

}
